import MainPage from "./components/MainPage/MainPage";
import OlderGroup from "./components/OlderGroup/OlderGroup";
import YoungerGroup from "./components/YoungerGroup/YoungerGroup";
import AboutUs from "./components/AboutUs/AboutUs";
import Contacts from "./components/Contacts/Contacts";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const routesData = [
  { path: "*", element: <MainPage /> },
  { path: "/older-group", element: <OlderGroup /> },
  { path: "/younger-group", element: <YoungerGroup /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/contacts", element: <Contacts /> },
];

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <main>
          <Routes>
            {routesData.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </main>
      </BrowserRouter>
    </div>
  );
}

export default App;
