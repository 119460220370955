import React from "react";
import {
  GroupContent,
  YoungerGroupHeader,
  GroupTitle,
  GroupAge,
  GroupInfoList,
  GroupInfo,
  GroupInfoBold,
  GroupInfoPrivateLessons,
  ExampleImages,
  ExampleImage,
  GroupButton,
  ButtonText,
} from "../../assets/styles/styled-components";
import youngerGroupExample1 from "../../assets/images/younger-group-example1.jpg";
import youngerGroupExample2 from "../../assets/images/younger-group-example2.jpg";

function YoungerGroup() {
  return (
    <GroupContent>
      <YoungerGroupHeader />
      <GroupTitle>МЛАДШАЯ ГРУППА</GroupTitle>
      <GroupAge>9 - 13 лет</GroupAge>
      <GroupAge>12000/мес.</GroupAge>
      <GroupInfoList>
        <GroupInfo>
          Занятия 2 раза в неделю по 2 часа (3 академических часа по 40 мин).
        </GroupInfo>
        <GroupInfo>
          Группа <GroupInfoBold>5 - 6 человек,</GroupInfoBold> индивидуальный
          подход к каждому учащемуся по его запросам.
        </GroupInfo>
        <GroupInfo>
          В процессе обучения ребятам даются элементарные основы по рисунку,
          живописи и композиции, необходимые для поступления в художественные
          школы.
        </GroupInfo>
        <GroupInfo>
          Простой натюрморт (построение/тональное решение/живопись).
        </GroupInfo>
        <GroupInfo>
          Композиция с 2 - 3мя фигурами человека по заданным темам.
        </GroupInfo>
        <GroupInfo>
          У учеников формируется необходимое портфолио к поступлению в
          образовательные учереждения.
        </GroupInfo>
        <GroupInfoPrivateLessons>
          Есть возможность частных занятий, отдельно от групп. Нагрузка по часам
          и оплате в таком случае варьируется.
        </GroupInfoPrivateLessons>
      </GroupInfoList>

      <ExampleImages>
        <ExampleImage
          src={youngerGroupExample1}
          alt="Ребенок рисует карандашом натюрморт с кувшином, кружкой и гранатом"
          loading="lazy"
        />
        <ExampleImage
          src={youngerGroupExample2}
          alt="Готовый натюрморт с кувшином, кружкой и гранатом"
          loading="lazy"
        />
      </ExampleImages>
      <GroupButton to="/contacts">
        <ButtonText>ЗАПИСЬ</ButtonText>
      </GroupButton>
    </GroupContent>
  );
}

export default YoungerGroup;
