import React from "react";
import {
  GroupContent,
  AboutUsGroupHeader,
  GroupTitle,
  GroupInfoList,
  GroupInfo,
  GroupInfoAboutUs,
  GroupInfoPrivateLessons,
  GroupButton,
  ButtonText,
} from "../../assets/styles/styled-components";
import aboutUsFooter from "../../assets/images/about-us-footer.jpg";

function AboutUs() {
  return (
    <GroupContent>
      <AboutUsGroupHeader />
      <GroupInfoList>
        <GroupInfoAboutUs>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;На наших занятиях в камерных группах с
          индивидуальным подходом вы сможете решить необходимые для вас задачи в
          изобразительном искусстве.
        </GroupInfoAboutUs>
        <GroupInfoAboutUs>
          <ul>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Продвинуться в изучении:
            <GroupInfo>живописи (акварель, гуашь, темпера, масло)</GroupInfo>
            <GroupInfo>рисунка (тонального, конструктивного)</GroupInfo>
            <GroupInfo>композиции (академической, фигуративной)</GroupInfo>
          </ul>
        </GroupInfoAboutUs>

        <GroupInfoAboutUs>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Под внимательным сопровождением
          педагогов с академическим образованием и обширным педагогическим
          опытом.
        </GroupInfoAboutUs>
        <GroupInfoAboutUs>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Наши преподаватели активные участники
          ежегодных городских, региональных и зональных художественных выставок
          СПб, лауреаты педагогических конкурсов от министерства культуры СПб.
        </GroupInfoAboutUs>
      </GroupInfoList>
      <GroupTitle>ВЗРОСЛАЯ ГРУППА</GroupTitle>
      <GroupInfoList>
        <GroupInfo>
          Занятия 2 раза в неделю по 2 часа (12000р/мес) или 1 раз в неделю по 2
          часа (8000р/мес).
        </GroupInfo>
        <GroupInfo>
          Производим набор группы старшего возраста с любым уровнем подготовки,
          желающих углубленно изучать живопись/рисунок/композицию.
        </GroupInfo>
        <GroupInfoPrivateLessons>
          Есть возможность частных занятий, отдельно от групп. Нагрузка по часам
          и оплате в таком случае варьируется.
        </GroupInfoPrivateLessons>
      </GroupInfoList>
      <GroupButton to="/contacts">
        <ButtonText>ЗАПИСЬ</ButtonText>
      </GroupButton>
      <img src={aboutUsFooter} alt="" loading="lazy" />
    </GroupContent>
  );
}

export default AboutUs;
