import React from "react";
import {
  GroupContent,
  OlderGroupHeader,
  GroupTitle,
  GroupAge,
  GroupInfoList,
  GroupInfo,
  GroupInfoBold,
  GroupInfoPrivateLessons,
  ExampleImages,
  ExampleImage,
  GroupButton,
  ButtonText,
} from "../../assets/styles/styled-components";
import olderGroupExample1 from "../../assets/images/older-group-example1.jpg";
import olderGroupExample2 from "../../assets/images/older-group-example2.jpg";

function OlderGroup() {
  return (
    <GroupContent>
      <OlderGroupHeader />
      <GroupTitle>СТАРШАЯ ГРУППА</GroupTitle>
      <GroupAge>14 - 16 лет</GroupAge>
      <GroupAge>12000/мес.</GroupAge>
      <GroupInfoList>
        <GroupInfo>
          Занятия 2 раза в неделю по 2 часа (3 академических часа по 40 мин).
        </GroupInfo>
        <GroupInfo>
          Группа <GroupInfoBold>5 - 6 человек,</GroupInfoBold> индивидуальный
          подход к каждому учащемуся по его запросам.
        </GroupInfo>
        <GroupInfo>
          В процессе обучения у учеников формируется портфолио - рисунки
          живопись, композиция.
        </GroupInfo>
        <GroupInfo>
          Выполненные рисунки могут служить в качестве портфолио при поступлении
          в колледж, ВУЗы, и при дальнейшем развитии в художественной сфере.
        </GroupInfo>
        <GroupInfoPrivateLessons>
          Есть возможность частных занятий, отдельно от групп. Нагрузка по часам
          и оплате в таком случае варьируется.
        </GroupInfoPrivateLessons>
      </GroupInfoList>

      <ExampleImages>
        <ExampleImage
          src={olderGroupExample1}
          alt="Натюморт на фоне ткани с яблоком, фонарем и кофемолкой"
          loading="lazy"
        />
        <ExampleImage
          src={olderGroupExample2}
          alt="Пример работы ученика старшей группы - натюрморт с гипсами"
          loading="lazy"
        />
      </ExampleImages>
      <GroupButton to="/contacts">
        <ButtonText>ЗАПИСЬ</ButtonText>
      </GroupButton>
    </GroupContent>
  );
}

export default OlderGroup;
