import styled from "styled-components";
import { Link } from "react-router-dom";
import mobileHeader from "../images/mobile-header.jpg";
import olderGroupBg from "../images/older-group-bg.jpg";
import youngerGroupBg from "../images/younger-group-bg.jpg";
import adultGroupBg from "../images/adult-group-bg.jpg";
import buttonBg from "../images/button-bg.jpg";
import olderGroupHeader from "../images/older-group-header.jpg";
import youngerGroupHeader from "../images/younger-group-header.jpg";
import aboutUs from "../images/about-us-header.jpg";
import aboutUsDesktop from "../images/about-us-header-desktop.jpg";
import desktopBg from "../images/desktop-bg.jpg";

export const MainWrapper = styled.div`
  display: flex;
  overflow: hidden;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px;
  margin-left: 60px;
  width: -webkit-fill-available;
  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
  }
`;

export const Header = styled.header`
  font-size: 4vw;
  z-index: 2;
  position: relative;
  left: 40%;
  width: max-content;
  @media (max-width: 800px) {
    font-size: 7vw;
    position: unset;
    width: auto;
    text-align: center;
    margin: 30px 0;
  }
`;

export const MobileHeader = styled.div`
  display: none;
  @media (max-width: 800px) {
    background: url(${mobileHeader}) no-repeat center 61% / cover;
    height: 20vh;
    display: block;
  }
`;

export const DesktopButton = styled(Link)`
  text-decoration: none;
  color: #000;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  height: 100vh;
  width: 60%;
  &::before {
    background: url(${desktopBg}) no-repeat left / cover;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
    height: 110vh;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

export const GroupWrapper = styled.div`
  background-color: #e7e7e7;
  display: flex;
  height: 25%;
  @media (max-width: 800px) {
    margin: 10px 30px;
    height: 25vh;
  }
`;

export const OlderGroupWrapper = styled(GroupWrapper)`
  background-color: #e7e7e7;
`;

export const YuongerGroupWrapper = styled(GroupWrapper)`
  background-color: #e4e1f6;
`;

export const AdultGroupWrapper = styled(GroupWrapper)`
  background-color: #feece2;
`;

export const LinkImg = styled.div`
  height: 100%;
  width: 65%;
`;

export const OlderGroupImg = styled(LinkImg)`
  background: url(${olderGroupBg}) no-repeat center 76% / cover;
`;

export const YuongerGroupImg = styled(LinkImg)`
  background: url(${youngerGroupBg}) no-repeat center 33% / cover;
`;

export const AdultGroupImg = styled(LinkImg)`
  background: url(${adultGroupBg}) no-repeat center center / cover;
`;

export const LinkText = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 25px;
`;

export const LinkInfo = styled.div`
  text-align: start;
  margin: 0 20px;
  width: 35%;
  @media (max-width: 800px) {
    font-size: 15px;
  }
`;

export const SignInButton = styled(Link)`
  color: #000;
  border: none;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  height: 25vh;
  margin: 22px;
  width: 100%;
  margin-left: auto;
  transform: rotate(-20deg);
  &::before {
    background: url(${buttonBg}) no-repeat center / contain;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
  }
  @media (min-width: 801px) {
    display: none;
  }
`;

export const GroupButton = styled(SignInButton)`
  @media (min-width: 801px) {
    display: block;
  }
`;

export const ButtonText = styled.p`
  width: fit-content;
  position: absolute;
  transform: translate(-50%, -50%) rotate(20deg);
  top: 51%;
  left: 49%;
  font-size: 5vh;
  letter-spacing: 3px;
`;

export const GroupContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const GroupHeader = styled.div`
  height: 20vh;
  margin-bottom: 30px;
  @media (min-width: 800px) {
    height: 30vh;
  }
`;

export const OlderGroupHeader = styled(GroupHeader)`
  background: url(${olderGroupHeader}) no-repeat center 27% / cover;
`;

export const YoungerGroupHeader = styled(GroupHeader)`
  background: url(${youngerGroupHeader}) no-repeat center 60% / cover;
`;

export const AboutUsGroupHeader = styled(GroupHeader)`
  background: url(${aboutUsDesktop}) no-repeat center 27% / cover;
  @media (max-width: 800px) {
    background: url(${aboutUs}) no-repeat center 80% / cover;
  }
`;

export const GroupTitle = styled.h2`
  font-size: 3vw;
  @media (max-width: 800px) {
    font-size: 5vw;
  }
`;

export const GroupAge = styled.p`
  font-size: 2vw;
  margin-top: 20px;
  @media (max-width: 800px) {
    margin-top: 10px;
    font-size: 5vw;
  }
`;

export const GroupInfoList = styled.ul`
  line-height: 50px;
  margin: 50px;
  text-align: left;
  margin: 20px 50px;
  font-size: 1.7vw;
  @media (max-width: 800px) {
    line-height: 25px;
    margin: 10px 30px;
    font-size: 3.5vw;
  }
`;

export const GroupInfo = styled.li`
  list-style: inside;
`;

export const GroupInfoBold = styled.span`
  font-weight: 400;
`;

export const GroupInfoAboutUs = styled(GroupInfo)`
  list-style: none;
`;

export const GroupInfoPrivateLessons = styled(GroupInfoAboutUs)`
  margin-top: 30px;
  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

export const ExampleImages = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
`;

export const ExampleImage = styled.img`
  height: 80vh;
  width: auto;
  @media (max-width: 800px) {
    width: 45vw;
    height: auto;
  }
`;

export const ContactsWrapper = styled.div`
  margin: 4vh 50px;

  font-size: 3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
    margin: 2vh 50px;
  }
`;

export const ContactTitle = styled(GroupTitle)`
  margin: 50px;
  @media (max-width: 800px) {
    margin: 30px;
  }
`;

export const ContactsLinks = styled.div`
  text-align: start;
  align-self: start;
  z-index: 1;
  line-height: 50px;
  font-size: 1.7vw;
  @media (max-width: 800px) {
    line-height: 25px;
    font-size: 3.5vw;
  }
`;

export const ContactsImage = styled.img`
  height: auto;
  margin: 22px;
  width: 50%;
  transform: rotate(-20deg);
  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const ContactsLink = styled.a`
  text-decoration: none;
  margin-left: 30px;
  color: #0000df;
  &:visited {
    color: #0000df;
  }
  @media (max-width: 800px) {
    margin-left: 10px;
  }
`;
