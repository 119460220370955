import React from "react";
import {
  MainWrapper,
  MainContent,
  Header,
  MobileHeader,
  DesktopButton,
  OlderGroupWrapper,
  YuongerGroupWrapper,
  AdultGroupWrapper,
  OlderGroupImg,
  YuongerGroupImg,
  AdultGroupImg,
  LinkText,
  LinkInfo,
  SignInButton,
  ButtonText,
} from "../../assets/styles/styled-components";

function MainPage() {
  return (
    <MainWrapper>
      <MainContent>
        <MobileHeader />
        <Header>ОБУЧЕНИЕ РИСОВАНИЮ</Header>
        <OlderGroupWrapper>
          <LinkText to="/older-group">
            <OlderGroupImg />
            <LinkInfo>
              <p>СТАРШАЯ ГРУППА</p>
              <p>14 - 16 ЛЕТ</p>
            </LinkInfo>
          </LinkText>
        </OlderGroupWrapper>
        <YuongerGroupWrapper>
          <LinkText to="/younger-group">
            <YuongerGroupImg />
            <LinkInfo>
              <p>МЛАДШАЯ ГРУППА</p>
              <p>9 - 13 ЛЕТ</p>
            </LinkInfo>
          </LinkText>
        </YuongerGroupWrapper>
        <AdultGroupWrapper>
          <LinkText to="/about-us">
            <AdultGroupImg />
            <LinkInfo>
              <p>О НАС</p>
              <p>И ВЗРОСЛАЯ ГРУППА</p>
            </LinkInfo>
          </LinkText>
        </AdultGroupWrapper>
      </MainContent>
      <SignInButton to="/contacts">
        <ButtonText>ЗАПИСЬ</ButtonText>
      </SignInButton>
      <DesktopButton to="/contacts"></DesktopButton>
    </MainWrapper>
  );
}

export default MainPage;
