import React from "react";
import {
  ContactsWrapper,
  ContactTitle,
  ContactsLinks,
  ContactsLink,
  ContactsImage,
} from "../../assets/styles/styled-components";
import palette from "../../assets/images/button-bg.jpg";

function Contacts() {
  return (
    <ContactsWrapper>
      <ContactTitle>Контакты</ContactTitle>
      <ContactsLinks>
        <p>
          EMAIL:
          <ContactsLink
            href="mailto:palette.artstudia@gmail.com?body=Добрый%20день,%20хочу%20узнать%20подробнее%20о%20записи%20в%20группы."
            target="_blank"
            rel="noreferrer"
          >
            palette.artstudia@gmail.com
          </ContactsLink>
        </p>
        <p>
          WHATSAPP:
          <ContactsLink
            href="https://wa.me/79650879036?text=Добрый%20день,%20хочу%20узнать%20подробнее%20о%20записи%20в%20группы."
            target="_blank"
            rel="noreferrer"
          >
            +7(965)087-90-36,
          </ContactsLink>
          <ContactsLink
            href="https://wa.me/79818333750?text=Добрый%20день,%20хочу%20узнать%20подробнее%20о%20записи%20в%20группы."
            target="_blank"
            rel="noreferrer"
          >
            +7(981)833-37-50
          </ContactsLink>
        </p>
        <p>
          TELEGRAM:
          <ContactsLink
            href="https://t.me/art_palitr"
            target="_blank"
            rel="noreferrer"
          >
            @art_palitr
          </ContactsLink>
        </p>
        <p>
          АДРЕС:
          <ContactsLink href="https://yandex.ru/maps/-/CDSVYR37">
            Санкт-Петербург, Васильевский остров, Кадетская линия ВО, д.5,
            корп.2Д.
          </ContactsLink>
          <p>
            Вавилов лофт, Зеленая лестница, 4 этаж, Аудитория 4411. Ст. метро
            Спортивная, выход 1 на Кадетскую линию (10 мин. пешком), ст. метро
            Васильеостровская (15 мин. пешком).
          </p>
        </p>
      </ContactsLinks>
      <ContactsImage src={palette} alt="" />
    </ContactsWrapper>
  );
}

export default Contacts;
